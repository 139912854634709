<template>
  <v-row justify="start">
    <v-dialog v-model="updateFaq" persistent max-width="400px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Add FAQ</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text v-if="faqItem">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Pertanyaan"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  rounded
                  v-model="faqItem.pertanyaan"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Jawaban"
                  required
                  outlined
                  dense
                  rounded
                  hide-details="auto"
                  v-model="faqItem.jawaban"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="updateItem(faqItem)"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  name: "addFaq",
  mixins: [validationMixin],
  validations: {
    quest: {
      required,
      maxLength: maxLength(100)
    },
    answer: {
      required
    }
  },
  props: ["updateFaq", "faqItem"],
  computed: {
    errQuest() {
      const errors = [];
      if (!this.$v.quest.$dirty) return errors;
      !this.$v.quest.maxLength &&
        errors.push("Pertanyaan maksimal 100 karakter!");
      !this.$v.quest.required && errors.push("Pertanyaan harus di isi!");
      return errors;
    },
    errAnswr() {
      const errors = [];
      if (!this.$v.answer.$dirty) return errors;
      !this.$v.answer.required && errors.push("Jawaban harus di isi!");
      return errors;
    }
  },
  data() {
    return {
      quest: "",
      answer: ""
    };
  },
  methods: {
    updateItem(data) {
      this.$store.dispatch("faq/updateFaq", data).then(data => {
        if (data.data.status == "success") {
          this.$emit("close");
          Swal.fire({
            icon: "success",
            title: data.data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        } else {
          Swal.fire({
            icon: "error",
            title: data.data.data.errors.nama[0],
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        }
      });
    }
  }
};
</script>

<style></style>
