<template>
  <div
    :class="
      role
        ? '_bg-default _80w _100vh pb-3 px-3'
        : '_bg-default _100vh pb-3 px-3'
    "
  >
    <Nav />
    <div v-if="faq" class="_100top pa-3">
      <div class="d-flex justify-space-between align-center px-4">
        <h1>FAQ</h1>
        <v-btn v-if="role" color="purple" dark @click="dialogFaq = true"
          >Add FAQ</v-btn
        >
      </div>
      <v-expansion-panels accordion class="mt-3">
        <v-expansion-panel v-for="(item, i) in faq.data" :key="i" class="mb-2">
          <v-expansion-panel-header>
            <h3>{{ item.pertanyaan }}</h3></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-card
              class="d-flex justify-end my-3"
              elevation="0"
              v-if="role == 1"
            >
              <v-btn icon small color="blue" @click="updateItem(item)"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
              <v-btn icon small color="red" @click="deleteItem(item)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-card>
            <p>
              {{ item.jawaban }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="pa-3">
      <AddFaq v-bind:dialogFaq="dialogFaq" @close="closeDialog" />
      <UpdateFaq
        v-bind:updateFaq="updateFaq"
        v-bind:faqItem="faqItem"
        @close="closeDialog"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import AddFaq from "../../components/Admin/Modal/addFaq.vue";
import UpdateFaq from "../../components/Admin/Modal/updateFaq.vue";
import Nav from "../../components/General/Nav.vue";

export default {
  components: { AddFaq, UpdateFaq, Nav },
  name: "frequentlyaskquest",

  data() {
    return {
      dialogFaq: false,
      updateFaq: false,
      faqItem: null
    };
  },
  computed: {
    ...mapState({
      faq: state => state.faq.faq,
      role: state => state.role
    })
  },

  mounted() {
    this.fetchItem();
  },

  methods: {
    fetchItem() {
      this.$store.dispatch("faq/listFaq");
    },
    updateItem(item) {
      this.faqItem = item;
      this.updateFaq = true;
    },
    closeDialog() {
      this.dialogFaq = false;
      this.updateFaq = false;
      this.fetchItem();
    },
    deleteItem(item) {
      //delete  function
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          //if ok then hit api delete iten
          this.$store.dispatch("faq/deleteFaq", { id: item.id }).then(() => {
            this.fetchItem(); //if success refetch data all
            Swal.fire({
              title: "Deleted",
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
              position: "top",
              toast: true
            });
          });
        }
      });
    }
  }
};
</script>

<style scoped></style>
