<template>
  <v-row justify="start">
    <v-dialog v-model="dialogFaq" persistent max-width="400px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Add FAQ</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Pertanyaan"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  rounded
                  v-model="quest"
                  :error-messages="errQuest"
                  @input="$v.quest.$touch()"
                  @blur="$v.quest.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Jawaban"
                  required
                  outlined
                  dense
                  rounded
                  hide-details="auto"
                  v-model="answer"
                  :error-messages="errAnswr"
                  @input="$v.answer.$touch()"
                  @blur="$v.answer.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="addItem">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  name: "addFaq",
  mixins: [validationMixin],
  validations: {
    quest: {
      required,
      maxLength: maxLength(100)
    },
    answer: {
      required
    }
  },
  props: ["dialogFaq"],
  computed: {
    errQuest() {
      const errors = [];
      if (!this.$v.quest.$dirty) return errors;
      !this.$v.quest.maxLength &&
        errors.push("Pertanyaan maksimal 100 karakter!");
      !this.$v.quest.required && errors.push("Pertanyaan harus di isi!");
      return errors;
    },
    errAnswr() {
      const errors = [];
      if (!this.$v.answer.$dirty) return errors;
      !this.$v.answer.required && errors.push("Jawaban harus di isi!");
      return errors;
    }
  },
  data() {
    return {
      quest: "",
      answer: ""
    };
  },
  methods: {
    addItem() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$store
          .dispatch("faq/createFaq", {
            pertanyaan: this.quest,
            jawaban: this.answer
          })
          .then(data => {
            console.log(data);
            if (data.status == "success") {
              this.nameCampus = "";
              this.$v.$reset();
              this.$emit("close");
              Swal.fire({
                icon: "success",
                title: data.message,
                showConfirmButton: false,
                position: "top",
                toast: true,
                timer: 3000
              });
            } else {
              Swal.fire({
                icon: "error",
                title: data.errors.nama[0],
                showConfirmButton: false,
                position: "top",
                toast: true,
                timer: 3000
              });
            }
          });
      }
    }
  }
};
</script>

<style></style>
